<template>
  <div class="globalFilter">
    <ul class="globalFilter__list">
      <p class="globalFilter__title">Selecciona una ciudad o sede</p>
      <li class="globalFilter__item">
        <label v-for="city in getCities" :key="city._id" class="globalFilter__label">
          <div>
            <input type="checkbox" :checked="filtersCity(city)" @change="(event) => setFilter(event, { city: city.cityName })" />
            <span class="globalFilter__cityTitle">
              {{ city.cityName }}
            </span>
          </div>

          <label v-for="office in cities[city.cityName]" :key="office._id" @change="(e) => setFilter(e, { office: office.officeName })" class="globalFilter__offices">
            <div class="globalFilter__officeItem">
              <input type="checkbox" :checked="filtersOffices(office)" />
              <p class="globalFilter__officesName">
                {{ office.officeName }}
              </p>
            </div>
          </label>
        </label>
      </li>
      <button @click="apply" class="globalFilter__apply">Aplicar</button>
    </ul>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        showOffices: {},
        filters: {
          city: [],
          office: [],
          area: [],
        },
        hasFilters: [],
      };
    },

    computed: {
      getCities() {
        return this.$store.state.cities.data;
      },
      getOffices() {
        return this.$store.getters["offices/getData"];
      },

      cities() {
        const citiesDb = {};
        this.getCities.forEach((city) => {
          const filterOffices = this.getOffices.filter((offi) => offi.cityName === city.cityName);
          citiesDb[city.cityName] = filterOffices;
          return;
        });
        return citiesDb;
      },

      filtersCity() {
        return function({ cityName }) {
          return this.filters["city"].includes(cityName);
        };
      },

      filtersOffices() {
        return function({ officeName }) {
          return this.filters["office"].flat().includes(officeName);
        };
      },
    },
    methods: {
      setFilter(event, { city, office, area }) {
        event.preventDefault();
        const isChecked = event.target.checked;

        if (city) {
          this.toggleArrayValue("city", city);
          const officesFilter = this.getOffices.filter(({ cityName }) => cityName === city).map(({ officeName }) => officeName);
          this.filters.office = isChecked ? [...this.filters.office, ...officesFilter] : this.filters.office.filter((office) => !officesFilter.includes(office));
          this.showOffices = { ...this.showOffices, [city]: isChecked };
        }

        if (office) {
          this.toggleArrayValue("office", office);
        }

        if (area) {
          this.toggleArrayValue("area", area);
        }
      },

      toggleArrayValue(key, value) {
        const array = this.filters[key];
        const found = array.includes(value);
        if (found) {
          this.filters[key] = array.filter((item) => item !== value);
        } else {
          this.filters[key].push(value);
        }
      },

      cleanFilters(filter) {
        if (filter === "all") {
          this.filters = {
            city: [],
            office: [],
            area: [],
          };
        }
        Object.entries(this.filters).map(([key, value]) => {
          if (value.includes(filter)) {
            this.filters[key] = this.filters[key].filter((typeFilter) => typeFilter !== filter);
            if (key == "city") {
              const officesByCity = this.getOffices.filter(({ cityName }) => cityName === filter).map(({ officeName }) => officeName);
              this.filters["office"] = this.filters["office"].filter((office) => !officesByCity.includes(office));
            }
          }
        });
        this.apply();
      },

      apply(e) {
        e?.preventDefault();
        const typeOfFilters = [...this.filters["office"], ...this.filters["city"], ...this.filters["area"]];
        this.hasFilters = typeOfFilters;
        this.$emit("filters", this.hasFilters);
      },

      deleteFilter(filter) {
        this.cleanFilters(filter);
      },
    },
  };
</script>

<style lang="scss">
  .globalFilter {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    padding: 1rem;
    padding-bottom: 2rem;
    z-index: 11;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 1px 4px 0px #00000040;
    list-style: none;

    &__list {
      position: relative;
    }

    &__title {
      margin-bottom: 1rem;
    }

    &__item {
      @include Flex(column, flex-start, flex-start);
    }
    &__label {
      margin-bottom: 1rem;
      cursor: pointer;
    }

    &__cityTitle {
      font-weight: bold;
    }

    &__cleanAllFilters {
      @include Flex();
      margin-top: 0.5rem;
      padding: 0.5rem 0rem;
    }
    &__offices {
      margin-left: 1rem;
    }
    &__officeItem {
      @include Flex();
    }
    &__officesName {
      margin-left: 0.5rem;
    }
    &__apply {
      position: absolute;
      bottom: -1rem;
      right: 0;
      align-self: flex-end;
      width: 6rem;
      color: $primary;
      font-weight: bold;
      background: transparent;
      border: 1px solid $primary;
      border-radius: 1rem;
      transition: all 0.3s ease-in-out;
    }
    &__apply:hover {
      background: $primary;
      color: #fff;
    }

    @media (min-width: 769px) {
    }
  }
</style>
